<script lang="ts">
	import { goto } from '$app/navigation';
	import { page } from '$app/stores';
	import LdTag from '$components/Ldjson/LdTag.svelte';
	import type { Schema } from '$components/Ldjson/json-ld';
	import Footer from '$components/footer/Footer.svelte';
	import Cards from '$components/landing/Cards.svelte';
	import Checklist from '$components/landing/Checklist.svelte';
	import Contact from '$components/landing/Contact.svelte';
	import InteractiveTitle from '$components/landing/InteractiveTitle.svelte';
	import ArticleBrowseSection from '$components/landing/articles/ArticleBrowseSection.svelte';
	import ArticleCarousel from '$components/landing/articles/ArticleCarousel.svelte';
	import FaqSection from '$components/landing/faqs/FaqSection.svelte';
	import LocationSelection from '$components/landing/location-inputs/LocationSelection.svelte';
	import PopularDestinationCarousal from '$components/landing/popular-destinations/PopularDestinationCarousal.svelte';
	import ReviewsSection from '$components/landing/reviews/ReviewsSection.svelte';
	import HiddenLinks from '$components/seo/HiddenLinks.svelte';
	import type { LANGUAGE } from '$lib/constants/locale';
	import { SOCIALS_CONFIG } from '$lib/constants/socials';
	import CardIcon from '$lib/icons/card-icon.svelte';
	import GroupIcon from '$lib/icons/group-icon.svelte';
	import LocationIcon from '$lib/icons/pickup-details/location-icon.svelte';
	import { isLocationSelectionIntersecting } from '$store/isLocationSelectionIntersecting';
	import type { Article } from '$types/articles.types';
	import type { PopularDestination } from '$types/popularDestinations.types';
	import type { Review } from '$types/review';
	import { LINK_BOOKING, LOCALIZELINK, LINK_ROOT } from '$utils/link';
	import { getContext, onMount } from 'svelte';
	import isDesktop from '$store/isDesktop';
	import SeoMetadata from '$components/atoms/SeoMetadata.svelte';
	import type { SeoMetadataType, PagesType } from '$types/seo.types';
	let variant: 'offers' | 'benefits' = 'offers';
	const reviews = getContext<Review[]>('reviews');
	const popularDestinations = getContext<PopularDestination[]>('popularDestinations');
	const blogsInfo = getContext<Article[]>('blogsInfo');
	let t = getContext('page-contents-landing') as Record<string, string>;
	let lang = getContext('lang') as LANGUAGE;
	const seoMetadata = getContext('seo-metadata') as Record<PagesType, SeoMetadataType>;
	const updateScrollPosition = () => {
		let isValidScrollPosition = false;
		if ($page?.url?.hash) {
			const element = document.querySelector(`[data-location="${$page.url.hash}"]`);
			if (element) {
				isValidScrollPosition = true;
			}
		}
		if (!isValidScrollPosition) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	};

	onMount(() => {
		// taking the scroll to the top if the user navigates back
		setTimeout(() => {
			updateScrollPosition();
		}, 0);

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					$isLocationSelectionIntersecting = entry.isIntersecting;
				});
			},
			{
				root: null,
				rootMargin: '10px',
				threshold: 0.5
			}
		);
		const locationSelection = document.getElementById('location-selection-landing');
		if (!locationSelection) return;
		observer.observe(locationSelection!);
		return () => {
			observer.disconnect();
		};
	});

	const schema: Schema = {
		'@context': 'https://schema.org',
		'@type': 'LocalBusiness',
		name: '89 Transfers | Palma Airport Transfers & Mallorca Transfers',
		openingHours: 'Open 24 hours from Monday to Sunday',
		areaServed: {
			'@type': 'GeoCircle',
			name: '89 Transfers | Palma Airport Transfers & Mallorca Transfers service area',
			geoMidpoint: {
				'@type': 'GeoCoordinates',
				postalCode: '07004',
				latitude: '39.5786859',
				longitude: '2.6567886',
				description: 'Our office location can be found here.',
				name: '89 Transfers | Palma Airport Transfers & Mallorca Transfers geo location'
			},
			geoRadius: '20000',
			description:
				'89 Transfers | Palma Airport Transfers & Mallorca Transfers proudly serves the entire city of Palma Illes Balears'
		},
		email: 'info@89transfers.com',
		address: {
			'@type': 'PostalAddress',
			postalCode: '07004',
			addressRegion: 'Illes Balears',
			addressCountry: 'Spain',
			streetAddress: "29 Carrer d'Eusebi Estada",
			addressLocality: 'Palma'
		},
		paymentAccepted: 'Cash, Credit Card',
		telephone: '+34871153866',
		image: {
			'@type': 'ImageObject',
			name: '89 Transfers | Palma Airport Transfers & Mallorca Transfers image',
			url: 'https://89transfers.com/Image-Laptop-Hotel.jpg'
		},
		contactPoint: {
			'@type': 'ContactPoint',
			name: '89 Transfers | Palma Airport Transfers & Mallorca Transfers inquiry',
			availableLanguage: 'https://en.wikipedia.org/wiki/List_of_dialects_of_the_English_language',
			telephone: '+34871153866',
			contactType: 'customer support',
			'@id': 'https://89transfers.com/contact/'
		},
		geo: {
			'@type': 'GeoCoordinates',
			latitude: '39.5786859',
			longitude: '2.6567886',
			description: 'Our office location can be found here.',
			name: '89 Transfers | Palma Airport Transfers & Mallorca Transfers geo location'
		},
		description:
			'During the last few years we have specialized in transfers to and from the airport to hotels and villas or private homes. For groups up to 12 passengers with luggage. All our transfers are private, carried out in minibusses and vans. We work hard to get the best service at one of the lowest prices out there.',
		priceRange: '100-10000',
		url: 'https://89transfers.com/',
		currenciesAccepted: '€',
		hasMap: 'https://www.google.com/maps?cid=3892607717810473371',
		logo: 'https://89transfers.com/logo.svg',
		serviceArea: {
			'@type': 'GeoCircle',
			name: '89 Transfers | Palma Airport Transfers & Mallorca Transfers service area',
			geoMidpoint: {
				'@type': 'GeoCoordinates',
				postalCode: '07004',
				latitude: '39.5786859',
				longitude: '2.6567886',
				description: 'Our office location can be found here.',
				name: '89 Transfers | Palma Airport Transfers & Mallorca Transfers geo location'
			},
			geoRadius: '20000',
			description:
				'89 Transfers | Palma Airport Transfers & Mallorca Transfers proudly serves the entire city of Palma'
		},
		sameAs: [
			SOCIALS_CONFIG['facebook'],
			SOCIALS_CONFIG['instagram'],
			SOCIALS_CONFIG['twitter'],
			SOCIALS_CONFIG['youtube'],
			SOCIALS_CONFIG['whatsapp'],
			'https://www.yelp.com/biz/89-transfers-palma-de-mallorca',
			''
		],
		founder: {
			'@type': 'Person',
			name: 'Ramon Pons',
			telephone: '+34 871 15 38 66',
			email: 'info@89transfers.com'
		},
		'@id': 'https://89transfers.com/'
	};
</script>

<SeoMetadata data={seoMetadata['landing']} link={LINK_ROOT} />
<div class="bg-grey-100 relative min-h-screen pb-10 pt-[100px] md:pt-10">
	<section class="min-h-620px relative mx-auto">
		<div class="flex flex-col md:relative md:block">
			<div class="relative mx-auto max-w-[1120px] px-4">
				<h1 class="sub-heading lh-42px md:lh-56px md:top-7rem mb-10 md:absolute">
					{t.hero_mallorca_text_the}
					<span class="text-linear-purple-100"> {t.hero_mallorca_text_transfers_mallorca} </span>
					{t.hero_mallorca_text_transfers_service} <br />
					...
					<span class="text-linear-purple-100">{t.hero_easier_than_ever} </span>
				</h1>
			</div>
			<div class="md:max-h-620px relative md:absolute md:right-0 md:top-0 md:z-0">
				<enhanced:img
					src="../../assets/hero.webp?w=710;500;300&format=avif;webp"
					sizes="(min-width:768px) 710px, (min-width:496px) 500px (max-width:495px) 300px"
					class="block h-auto w-full"
					alt="89 Transfers | Palma Airport Transfers & Mallorca Transfers hero image"
					fetchpriority="high"
					loading="eager"
				/>
			</div>
		</div>
		<div
			class="max-w-949px absolute left-1/2 top-[calc(100%-180px)] z-10 mx-2 w-[calc(100%-16px)] -translate-x-1/2"
			id="location-selection-landing"
			style="filter: drop-shadow(0px 2px 13px rgba(142, 122, 239, 0.45));"
		>
			{#if $isDesktop}
				<LocationSelection />
			{/if}
		</div>
	</section>
	<section class="my-20 bg-white px-5 py-10 md:py-5">
		<div class="mx-auto grid max-w-[1120px] gap-x-20 gap-y-10 md:grid-cols-3">
			<div class="max-w-450px flex flex-row items-center justify-start gap-3">
				<div class="text-grey-800">
					<LocationIcon variant="solid" />
				</div>
				<h2 class="rubik-regular-base text-grey-800">
					{t.ribbon_from_airport}
				</h2>
			</div>
			<div class="flex flex-row items-center justify-start gap-3">
				<div class="text-grey-800">
					<GroupIcon />
				</div>
				<h2 class="rubik-regular-base text-grey-800">{t.ribbon_passengers_luggage}</h2>
			</div>
			<div class="flex flex-row items-center justify-start gap-3">
				<div class="text-grey-800">
					<CardIcon />
				</div>
				<h2 class="rubik-regular-base text-grey-800">{t.ribbon_no_credit_card}</h2>
			</div>
		</div>
	</section>
	<section class="mb-40 px-5">
		<div class="mx-auto max-w-[1120px]">
			<InteractiveTitle bind:variant />
			<Cards {variant} />
		</div>
	</section>
	<section class="pt-45 pb-60px relative mb-40 w-full px-5 md:pt-20">
		<div class="absolute left-0 top-0 z-0 h-full w-full">
			<enhanced:img
				src="../../assets/landing-page-banner-bg.webp?w=1600;1280;768;400&format=avif;webp"
				sizes="(min-width:1600px) 1600px, (min-width:1280px) 1280px, (min-width:768px) 768px, (max-width:767px) 400px"
				class="h-full w-full object-fill object-center"
				alt="landing-page-banner-background"
				loading="lazy"
			/>
		</div>
		<div class="max-w-1120px relative z-10 mx-auto">
			<p class="sub-heading mb-10 !text-white">{t.banner_1_seven_minutes}</p>
			<p
				class="rubik-regular-base text-24px lh-40px z-1 max-w-900px relative mb-10 w-full text-white"
				style="letter-spacing: -0.5px;font-size: clamp(18px, 1.8vw + 5px, 24px);"
			>
				{t.banner_1_average_time}
			</p>
		</div>
		<div class="md:w-112 w-77 absolute right-0 top--10 z-0 h-auto">
			<enhanced:img
				src="../../assets/landing-page-banner-illustration.webp?w=400;300;200&format=avif;webp"
				sizes="(min-width:1280px) 400px, (min-width:768px) 300px, (max-width:767px) 200px"
				class="h-full w-full object-cover object-center"
				alt="landing-page-banner-illustration"
				loading="lazy"
			/>
		</div>
	</section>
	<section class="mb-40">
		<ReviewsSection data={reviews} />
	</section>
	<section class="bg-purple py-15 relative px-5">
		<div class="max-w-1120px flex-between gap-y-15 relative z-10 mx-auto flex-col md:flex-row">
			<div class="flex flex-col items-center md:items-start">
				<h2 class="sub-heading mb-10 text-center md:text-left">
					{t.banner_2_planning_a_trip}
				</h2>
				<button
					class="rounded-5px karla-medium flex-center bg-linear-purple-100 cta
			 max-w-281px max-h-70px text-white"
					style="font-size: clamp(18px, 1.5vw + 5px, 24px); width: max(200px,25vw); height: max(50px,7vw); "
					aria-label="Book Transfer Now"
					on:click={() => goto(LOCALIZELINK(lang, LINK_BOOKING))}
				>
					{t.book_transfer_now}
				</button>
			</div>
			<Checklist />
		</div>
	</section>
	<section class="mt-25">
		<div class="max-w-1120px mx-auto">
			<h2 class="sub-heading mb-10 px-5">{t.popular_destinations_starting_from}</h2>
			<PopularDestinationCarousal data={popularDestinations} />
		</div>
	</section>
	<section class="py-15 relative mt-10 px-5">
		<div class="max-w-1120px mx-auto">
			<h2 class="sub-heading mb-10">{t.frequently_asked_questions_contact_us}</h2>
			<FaqSection />
		</div>
	</section>
	<section class="relative mt-10 px-5">
		<div class="max-w-1120px mx-auto">
			<h2 class="sub-heading mb-10">{t.contact_us_contact_us}</h2>
		</div>
		<div class="w-screen-without-scroll ml--5">
			<iframe
				width="100%"
				height="469"
				style="border:0"
				loading="lazy"
				allowfullscreen
				title="map"
				referrerpolicy="no-referrer-when-downgrade"
				src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDOCiPUC1JGDipoW4LxBs743OwBDUW8vvQ
			&q=89+Transfers,+Carrer+d'Eusebi+Estada,+Palma,+Spain"
			/>
		</div>
		<Contact />
	</section>
	<section class="relative mt-10 px-5">
		<div class="max-w-1120px mx-auto">
			<h2 class="sub-heading mb-10">{t.articles_articles}</h2>
			<div class="">
				<ArticleCarousel articles={blogsInfo.filter((blog) => blog.featuredPost)} />
			</div>
		</div>
	</section>
	<section class="relative mt-10 px-5">
		<div class="max-w-1120px mx-auto">
			<h2 class="sub-heading mb-10">{t.articles_browse_by_category}</h2>
			<ArticleBrowseSection {blogsInfo} showCtaBanner />
		</div>
	</section>
</div>
<Footer />
<HiddenLinks />
<LdTag {schema} />

<style type="postcss">
	.sub-heading {
		@apply karla-bold z-1 max-w-735px text-grey-800 relative w-full;
		font-size: clamp(34px, 3vw + 1rem, 48px);
	}
</style>
